<template>
  <div class="chat" id="Chat">
    <!-- 头部 -->

    <div class="topbar">
      <img @click="$router.push('/CooperateUs')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>

      <div class="head-title">{{ typeName }}</div>
    </div>

    <!-- 聊天内容 -->
    <div class="chat-content">
      <div
        class="content"
        id="chat_box"
        :style="
          status == 0 || status == 2
            ? 'height: calc(100vh - 200px);'
            : 'height: calc(100vh - 180px);'
        "
      >
        <div v-if="historyMessage">
          <div v-for="(item, index) in historyMessage.list" :key="index">
            <!-- 其他人的消息 -->
            <div v-if="item.msgType == 1">
              <div class="other-msg">
                <p>{{ item.createTime }}</p>
                <div class="avatar">
                  <img :src='require("@/assets/imgs/chat_left_icon.png")' alt="" />
                  <span>{{ item.message }}</span>
                </div>
              </div>
            </div>

            <!-- 自己的消息 -->
            <div v-if="item.msgType == 0">
              <div class="self-msg">
                <p>{{ item.createTime }}</p>
                <div class="avatar">
                  <img :src='require("@/assets/imgs/chat_right_icon.png")' alt="" />
                  <span>{{ item.message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="botbar">
      <!-- 待处理和处理中 -->
      <div class="unfinish" v-if="status == 0 || status == 2">
        <h3>{{ $t("上傳憑證(最多5張截圖)") }}</h3>
        <div class="addImg-content">
          <div class="addImg">
            <!-- 已经上传过的凭证 -->
            <div
              class="smallImg"
              v-for="(item, index) in UploadImage"
              :key="index"
              @click.stop="showBigImg(item)"
            >
              <img :src="item" alt="" />
            </div>
            <!-- 还未上传的凭证 -->
            <div
              class="smallImg"
              v-for="(item, index) in unUploadImage"
              :key="item.id"
              @click.stop="showBigImg(item)"
            >
              <img :src="item" alt="" />
              <v-icon @click="deleteImage(index)" size="20">mdi-close</v-icon>
            </div>

            <div class="upload">
              <!-- 已上传加上未上传图片不超过五张 -->
              <v-file-input
                v-if="unUploadImage.length + UploadImage.length < 5"
                v-model="imageFile"
                hide-details=""
                background-color="#fff"
                type="file"
                class="img-upload-input"
                solo
                id="img-upload-input"
                accept="image/jpg, image/jpeg, image/png, image/gif"
                prepend-icon="mdi-plus"
                @change="uploadImage()"
              ></v-file-input>
            </div>
          </div>
        </div>

        <div class="input-box">
          <div class="input">
            <v-text-field
              v-model="inputFile"
              hide-details=""
              autofocus
              :label="$t('輸入聊天室資訊')"
              rounded
              background-color="#ECECEC"
              solo
              @keyup.enter.native="sendMessage()"
            ></v-text-field>
          </div>
          <div class="icon" @click="sendMessage()">
            <v-icon>mdi-send</v-icon>
          </div>
        </div>
      </div>
      <!-- 已处理 -->
      <div class="finish" v-if="status == 1">
        <div class="addImg-content">
          <div class="addImg">
            <div
              class="smallImg"
              v-for="(item, index) in UploadImage"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 查看大圖 -->
      <v-dialog v-model="dialog" max-width="360" max-height="700">
        <v-img
          contain
          class="elevation-7 rounded-lg"
          :src="bigImg"
          :lazy-src="smallImg"
          ><template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
export default {
  inject: ["reload"],
  data() {
    return {
      dialog: false,
      bigImg: null,
      smallImg: null,
      timer: null,
      // 输入框绑定的值
      inputFile: "",
      imageFile: null,
      typeName: "",
      titleId: null,
      status: null,
      roomId: null,
      avatar: require("@/assets/imgs/Frame.png"),
      questionType: [],
      // 已经上传过的凭证照片
      UploadImage: [],
      // 展示上传图片
      unUploadImage: [],
      // 历史信息
      historyMessage: null,
    };
  },

  created() {
    var typeObj = JSON.parse(localStorage.getItem("ChatItem"));
    this.typeId = typeObj.typeId;
    this.typeName = typeObj.typeName;
    this.titleId = typeObj.titleId;
    this.status = typeObj.status;

    this.getQuestionType();
    this.getHistoryChatMessage();
    if (this.status == 0 || this.status == 2) {
      // 实现轮询
      this.timer = window.setInterval(() => {
        setTimeout(this.getNewChatmessage(), 0);
      }, 3000);
    }
  },
  methods: {
    deleteImage(index) {
      this.unUploadImage.splice(index, 1);
    },
    // 提交信息
    sendMessage() {
      if (!this.inputFile) {
        this.$toast(i18n.t("請說明您的問題"));
        return;
      }

      this.$post("/Chat/App_ChatSave", {
        titleId: this.titleId,
        message: this.inputFile,
        files: this.unUploadImage,
      }).then((res) => {
        if (res.code == 0) {
          this.historyMessage.list.push({
            msgType: 0,
            createTime: res.data,
            message: this.inputFile,
          });
          setTimeout(function () {
            if (document.querySelector("#chat_box"))
              document.querySelector("#chat_box").scrollTop =
                document.querySelector("#chat_box").scrollHeight;
          }, 0);
          if (this.unUploadImage.length) {
            this.UploadImage.push(this.unUploadImage);
            this.unUploadImage = [];
          }
          this.inputFile = "";
          this.unUploadImage = [];
          this.$toast(i18n.t("資訊上傳成功"));
        }
      });
    },
    // 上传图片
    uploadImage() {
      if (this.imageFile == null) {
        return;
      }
      var formData = new window.FormData();
      formData.append("file", this.imageFile);
      this.$post("/Chat/App_Uploadfile", formData).then((res) => {
        if (res.code == 0) {
          if (this.unUploadImage.length + this.UploadImage.length <= 4) {
            this.unUploadImage.push(res.data.fileName);
          } else {
            this.$toast(i18n.t("最多五張圖片"));
          }
        }
        console.log(res.data);
      });
    },
    // 问题类型
    getQuestionType() {
      this.$get("/Chat/GetQuestionType").then((res) => {
        this.questionType = res.data;
        this.questionType.forEach((item) => {
          if (item.value == this.titleId) {
            this.typeName = item.text;
          }
        });
      });
    },
    // 获取历史消息
    getHistoryChatMessage() {
      this.$get("/Chat/App_Chat", {
        titleId: this.titleId,
        typeId: this.typeId,
      }).then((res) => {
        this.historyMessage = res.data;
        this.UploadImage = res.data.images;
      });
    },
    // 获取最新的消息
    getNewChatmessage() {
      this.$get("/Chat/App_GetNewChat", {
        titleId: this.titleId,
      }).then((res) => {
        if (res.code == 0 && res.message != "") {
          for (let index = 0; index < res.data.length; index++) {
            res.data[index].msgType = 1;
          }
          this.historyMessage.list = this.historyMessage.list.concat(res.data);
        }
      });
    },
    showBigImg(item) {
      if (item.indexOf("-big") > 0) {
        this.bigImg = item;
        this.smallImg = item;
      } else {
        var hz = item.slice(item.lastIndexOf("."));
        var img = item.substring(0, item.lastIndexOf("."));
        this.bigImg = img + "-big" + hz;
        this.smallImg = item;
      }
      this.dialog = true;
    },
  },

  destroyed() {
    //离开页面是销毁
    clearInterval(this.timer);
    this.timer = null;
  },

  watch: {
    historyMessage() {
      setTimeout(function () {
        if (document.querySelector("#chat_box")) "";
        document.querySelector("#chat_box").scrollTop =
          document.querySelector("#chat_box").scrollHeight;
      }, 0);
    },
    immediate: true, //立即进行监听
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.chat {
  padding: 46px 0 110px;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    text-align: left;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .chat-content {
    padding: 10px 24px 0;
    background-color: #F0F6F9;
    .content {
      padding-bottom: 20px;
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      // 其他人消息
     .other-msg {
        margin: 10px 0;
        text-align: left;

        p {
          font-size: 14px;
          line-height: 20px;
          color: #8F8DA5;
          margin-bottom: 10px;
        }

        .avatar {
          display: flex;
          align-items: center;

          img {
            display: block;
            margin-right: 5px;
            width: 26px;
          }

          span {
            padding: 12px 29px 12px 10px;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            background: #00C7DB;
            border-radius: 20px 20px 20px 0px;
          }
        }
      }

      // 自己发送的消息
      .self-msg {
        margin-bottom: 41px !important;
        ;
        margin: 10px 0;
        text-align: right;

        p {
          font-size: 14px;
          line-height: 20px;
          color: #8F8DA5;
          margin-bottom: 10px;
        }

        .avatar {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          img {
            display: block;
            width: 26px;
            margin-left: 5px;
          }

          span {
            padding: 12px 33px 12px 27px;
            font-size: 12px;
            line-height: 17px;
            color: #FFFFFF;
            background: #F84279;
            border-radius: 20px 20px 0px 20px;
          }
        }
      }
    }
  }
  .botbar {
    background-color: #fff;
    border-top: 0.5px solid #d6d6d6;
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 24px;
    // 未处理完成
    .unfinish {
      h3 {
        padding: 10px 0;
        text-align: left;
        font-size: 14px;
        color: #8F8DA5;
      }
      // 添加上传图片
      .addImg-content {
        .addImg {
          // display: flex;
          display: inline-flex;
          align-items: center;
          padding: 15px 10px;
          width: 300px;
          overflow: auto;
          overflow-x: scroll;
          .smallImg {
            position: relative;
            width: 43px;
            height: 43px;
            margin-right: 10px;
            flex-shrink: 1;
            flex-grow: 0;
            min-width: 43px;
border: 1px solid #625D85;
border-radius: 5px;
            .v-icon {
              position: absolute;
              right: -10px;
              top: -10px;
              background-color: #f00;
              border-radius: 50%;
              color: #fff;
            }
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
          .upload::v-deep {
            position: relative;
            width: 43px;
            height: 43px;
            .v-input {
              height: 100%;
              width: 100%;
              .v-input__prepend-outer {
                margin: 0;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                z-index: 100;
                position: absolute;
                .v-icon {
                  width: 50px;
                  height: 50px;
                }
              }
              .v-input__control {
                width: 43px;
                height: 43px;
                min-height: unset;
                flex-direction: unset;
                border: 1px solid #d2d2d2;
                .v-file-input__text {
                  display: none;
                }
                .v-input__append-inner {
                  display: none;
                }
              }
            }
          }
        }
      }

      .input-box::v-deep {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .input {
          flex-grow: 1;
          .v-input__control{
            margin-right: .4rem;
            min-height: 1.08rem;
          }
          .v-input__slot {
            box-shadow: none;
            .v-label {
              font-size: 14px;
              color: #999999;
            }
          }
        }
        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f84279;
          border-radius: 50%;
          transform: rotate(-45deg);
          .v-icon {
            margin-left: 5px;
            color: #fff;
          }
        }
      }
    }
    // 处理完成
    .finish {
      h3 {
        padding: 10px 0;
        text-align: left;
        font-size: 14px;
        color: #999999;
      }
      // 添加上传图片
      .addImg-content {
        .addImg {
          // display: flex;
          display: inline-flex;
          align-items: center;
          padding: 15px 10px;
          width: 300px;
          overflow: auto;
          overflow-x: scroll;
          .smallImg {
            position: relative;
            width: 43px;
            height: 43px;
            border-radius: 5px;
            border: 1px solid #d2d2d2;
            margin-right: 10px;
            flex-shrink: 1;
            flex-grow: 0;
            min-width: 43px;
            .v-icon {
              position: absolute;
              right: -10px;
              top: -10px;
              background-color: #f00;
              border-radius: 50%;
              color: #fff;
            }
          }
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
          .upload::v-deep {
            position: relative;
            width: 43px;
            height: 43px;
            .v-input {
              height: 100%;
              width: 100%;
              .v-input__prepend-outer {
                margin: 0;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                z-index: 100;
                position: absolute;
                .v-icon {
                  width: 50px;
                  height: 50px;
                }
              }
              .v-input__control {
                width: 43px;
                height: 43px;
                min-height: unset;
                flex-direction: unset;
                border: 1px solid #d2d2d2;
                .v-file-input__text {
                  display: none;
                }
                .v-input__append-inner {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>